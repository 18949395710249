import React from 'react'
import { css } from 'emotion'

const Typography = ({ children, style, variant, ...props }) =>
  variant === 'caption' ? (
    <p
      className={css({
        fontSize: 12,
        ...style
      })}
      {...props}
    >
      {children}
    </p>
  ) : variant === 'subheading' ? (
    <h2
      className={css({
        fontSize: 16,
        ...style
      })}
      {...props}
    >
      {children}
    </h2>
  ) : variant === 'display2' ? (
    <h1
      className={css({
        fontSize: 42,
        fontWeight: 800,
        ...style
      })}
      {...props}
    >
      {children}
    </h1>
  ) : variant === 'display1' ? (
    <h1
      className={css({
        fontSize: 36,
        fontWeight: 800,
        ...style
      })}
      {...props}
    >
      {children}
    </h1>
  ) : variant === 'headline' ? (
    <h1
      className={css({
        fontSize: 24,
        fontWeight: 400,
        ...style
      })}
      {...props}
    >
      {children}
    </h1>
  ) : (
    <p
      className={css({
        fontSize: 14,
        ...style
      })}
      {...props}
    >
      {children}
    </p>
  )

export default Typography
