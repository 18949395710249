// import lodash from 'lodash'

const defaultState = {
  items: {
    byCreatedDate: [],
    byParagraphId: {}
  }
}

const comments = (state = defaultState, action) => {
  switch (action.type) {
    // case 'GET_COMMENTS':
    //   let items = action.payload.response.data.value
    //
    //   if (items.length === 0) {
    //     items = undefined
    //   } else {
    //     items = lodash.mapKeys(items, 'Id')
    //   }
    //
    //   return {
    //     items: {
    //       byCreatedDate: state.items.byCreatedDate,
    //       byParagraphId: {
    //         ...state.items.byParagraphId,
    //         [action.payload.paragraphId]: items
    //       }
    //     }
    //   }
    case 'GET_RECENT_COMMENTS':
      return {
        items: {
          byCreatedDate: action.payload.data,
          byParagraphId: state.items.byParagraphId
        }
      }
    case 'CREATE_COMMENT': {
      let comment = action.payload.response.data
      delete comment['odata.metadata']
      let paragraphId = action.payload.paragraphId
      let comments

      if (comment.Parent) {
        comments = {
          ...state.items.byParagraphId[paragraphId],
          [comment.Parent.Id]: {
            ...state.items.byParagraphId[paragraphId][comment.Parent.Id],
            Children: [
              ...state.items.byParagraphId[paragraphId][comment.Parent.Id]
                .Children,
              comment
            ]
          }
        }
      } else {
        comments = {
          ...state.items.byParagraphId[paragraphId],
          [comment.Id]: comment
        }
      }

      return {
        items: {
          byCreatedDate: state.items.byCreatedDate,
          byParagraphId: {
            ...state.items.byParagraphId,
            [paragraphId]: comments
          }
        }
      }
    }
    case 'CREATE_SCOPED_COMMENT': {
      const { data, paragraphId } = action.payload
      const ParentCommentId = data.ParentCommentId

      let comments

      if (ParentCommentId) {
        comments = {
          ...state.items.byParagraphId[paragraphId],
          [ParentCommentId]: {
            ...state.items.byParagraphId[paragraphId][ParentCommentId],
            Children: [
              ...state.items.byParagraphId[paragraphId][ParentCommentId]
                .Children,
              data
            ]
          }
        }
      } else {
        comments = {
          ...state.items.byParagraphId[paragraphId],
          [data.Id]: data
        }
      }

      return {
        items: {
          byCreatedDate: state.items.byCreatedDate,
          byParagraphId: {
            ...state.items.byParagraphId,
            [paragraphId]: comments
          }
        }
      }
    }
    default:
      return state
  }
}

// let comments = state
// if (createdComment.Parent) {
//   comments = state.map(comment => {
//     if (comment.Id === createdComment.Parent.Id) {
//       return {
//         ...comment,
//         Children: [...comment.Children, createdComment]
//       }
//     }
//     return comment
//   })
// } else {
//   comments = [...state, createdComment]
// }

export default comments
