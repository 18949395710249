import { request } from 'helpers'

const getCategories = () => dispatch =>
  request
    .get(
      'odata/Categories?$expand=Parent,Parent/Publisher,Parent/Customer&$orderby=Name&$select=*,Parent/*,Parent/Publisher/Id,Parent/Customer/Id'
    )
    .then(response =>
      dispatch({
        type: 'GET_CATEGORIES',
        payload: response
      })
    )

export default getCategories
