import { request } from 'helpers'

const getChapters = course => {
  return dispatch => {
    const url = `odata/Chapters?$filter=Course/FriendlyName eq '${
      course.FriendlyName
    }'&$expand=Course,Course/Parent,ParentChapter,InvisibleForCourses&$select=Course/Id,Course/Parent/Id,Course/Parent/FriendlyName,Id,Name,FriendlyName,Sort,AfterParentChapter,ParentChapter/Id,InvisibleForCourses/Id`
    return request
      .get(url, 'odata/Chapters')
      .then(childCourse => {
        if (course.Parent) {
          const url1 = `odata/Chapters?$filter=Course/FriendlyName eq '${
            course.Parent.FriendlyName
          }'&$expand=Course,Course/Parent,ParentChapter,InvisibleForCourses&$select=Course/Id,Course/Parent/Id,Id,Name,FriendlyName,Sort,AfterParentChapter,ParentChapter/Id,InvisibleForCourses/Id`
          return request.get(url1, 'odata/Chapters2').then(parentCourse => {
            dispatch({
              type: 'GET_CHAPTERS',
              payload: { childCourseId: course.Id, childCourse, parentCourse }
            })
          })
        } else {
          dispatch({
            type: 'GET_CHAPTERS',
            payload: { parentCourse: childCourse }
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export default getChapters
