import React, { useRef, useContext } from 'react'
import Notifications from 'components/Notifications'
import axios from 'axios'

const GlobalContext = React.createContext()

function GlobalProvider({ children }) {
  const notifications = useRef(null)

  function createError(error, message) {
    if (!axios.isCancel(error)) {
      notifications.current(message, 'error')
    } else {
      // handle aborted fetch calls
    }
  }

  function createSuccess(message) {
    notifications.current(message, 'success')
  }

  return (
    <React.Fragment>
      <Notifications children={add => (notifications.current = add)} />
      <GlobalContext.Provider value={{ createError, createSuccess }}>
        {children}
      </GlobalContext.Provider>
    </React.Fragment>
  )
}

function useGlobals() {
  const context = useContext(GlobalContext)
  return context
}

export { GlobalProvider, useGlobals }
