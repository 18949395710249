import React, { Component } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Link from './Public/Link'

const Application = React.lazy(() =>
  import(/* webpackChunkName: "Application" */ './Application')
)
const Public = React.lazy(() =>
  import(/* webpackChunkName: "Public" */ './Public')
)

class App extends Component {
  render() {
    if (this.props.user.token) {
      return <Application />
    }
    return (
      <Switch>
        <Route exact path="/link/:UID" render={props => <Link {...props} />} />
        <Route exact path="*" render={props => <Public {...props} />} />
      </Switch>
    )
  }
}

const mapStateToProps = ({ user }) => ({ user })
export default withRouter(connect(mapStateToProps)(App))
