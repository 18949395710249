import { compose, applyMiddleware, createStore } from 'redux'
import persistState from 'redux-sessionstorage'
import thunk from 'redux-thunk'
import promise from 'redux-promise'
import { createLogger } from 'redux-logger'
import reducers from 'reducers'

let middleware = applyMiddleware(thunk, promise)

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({ collapsed: true, diff: true })
  middleware = applyMiddleware(thunk, promise, logger)
}

const enhancer = compose(middleware, persistState())
const store = createStore(reducers, enhancer)

export default store
