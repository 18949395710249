const howtodo = {
  colors: {
    primary: '#000333',
    secondary: '#00c972',
    tertiary: '#089dd8',
    quaternary: '#f3f6f9'
  }
}

// const duka = {
//   colors: {
//     primary: '#4A97D1',
//     secondary: '#38ba9b',
//     tertiary: '#F4A29E',
//     quaternary: '#f3f6f9'
//   }
// }

export default howtodo
