import { request } from 'helpers'

const getRecentComments = () => dispatch => {
  // const url = 'odata/Comments?$orderby=CreatedDate desc&$top=5&$expand=Paragraph,Paragraph/Course,Paragraph/Course/Category&$select=Id,Name,CreatedDate,Text,Paragraph/FriendlyName,Paragraph/Id,Paragraph/Name,Paragraph/Course/FriendlyName,Paragraph/Course/Name,Paragraph/Course/Category/FriendlyName'
  const url = 'api/Comments'

  return request
    .get(url)
    .then(response => {
      dispatch({
        type: 'GET_RECENT_COMMENTS',
        payload: response
      })
    })
    .catch(error => {
      dispatch({
        type: 'ERROR',
        payload: error
      })
    })
}

export default getRecentComments
