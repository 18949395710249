import lodash from 'lodash'

const initialState = {
  items: {
    byId: {}
  }
}

export default function chapters(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_GET_CHAPTERS':
      let items = action.payload.data.value

      return {
        items: {
          byId: {
            ...state.items.byId,
            ...lodash.mapKeys(items, 'Id')
          }
        }
      }
    case 'ADMIN_CREATE_CHAPTER':
      let createdChapter = action.payload.data

      return {
        items: {
          byId: {
            ...state.items.byId,
            [createdChapter.Id]: createdChapter
          }
        }
      }
    case 'ADMIN_UPDATE_CHAPTER':
      let updatedChapter = action.payload.data

      return {
        items: {
          byId: {
            ...state.items.byId,
            [updatedChapter.Id]: {
              ...state.items.byId[updatedChapter.Id],
              Name: updatedChapter.Name
            }
          }
        }
      }

    case 'ADMIN_UPDATE_CHAPTER_VISIBILITY':
      let InvisibleForCourses = action.payload.response.data
      let chapterId = action.payload.chapterId
      let newState = {
        items: {
          byId: {
            ...state.items.byId,
            [chapterId]: {
              ...state.items.byId[chapterId],
              InvisibleForCourses
            }
          }
        }
      }
      return newState

    case 'ADMIN_DELETE_CHAPTER':
      return {
        items: {
          byId: lodash.omit(state.items.byId, action.Id)
        }
      }
    case 'ADMIN_SORT_CHAPTERS':
      const newItems = lodash.mapKeys(action.payload.data, 'Id')

      const changedObject = Object.keys(state.items.byId).reduce(
        (accumulator, key) => {
          accumulator[key] = { ...state.items.byId[key], ...newItems[key] }
          return accumulator
        },
        {}
      )

      return {
        items: {
          byId: changedObject
        }
      }
    case 'ADMIN_SORT_CHILD_CHAPTERS':
      const newItemsFix = action.payload.data.map(item => {
        const newChap = {
          ...item,
          ParentChapter: { Id: item.ParentChapter_Id }
        }
        delete newChap['item.ParentChapter_Id']
        return newChap
      })
      const newItems1 = lodash.mapKeys(newItemsFix, 'Id')

      const changedObject1 = Object.keys(state.items.byId).reduce(
        (accumulator, key) => {
          accumulator[key] = { ...state.items.byId[key], ...newItems1[key] }
          return accumulator
        },
        {}
      )

      return {
        items: {
          byId: changedObject1
        }
      }
    default:
      return state
  }
}
