import { mapKeys } from 'lodash'

const defaultState = {
  selectedId: 0,
  byId: {}
}

const courses = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_COURSE': {
      const selectedId = action.payload

      return {
        ...state,
        selectedId
      }
    }
    case 'GET_COURSE': {
      const course = action.payload
      let selectedId = state.selectedId

      if (course.Id) {
        selectedId = course.Id
      }

      return {
        ...state,
        selectedId: selectedId,
        byId: {
          ...state.byId,
          [selectedId]: {
            ...state.byId[selectedId],
            ...course
          }
        }
      }
    }
    case 'GET_SCOPED_CHAPTER': {
      const { Chapter, CourseId } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [CourseId]: {
            ...state.byId[CourseId],
            Chapters: [Chapter]
          }
        }
      }
    }
    case 'GET_COURSES': {
      const items = action.payload.data.value

      return {
        ...state,
        byId: mapKeys(items, 'Id')
      }
    }
    default: {
      return state
    }
  }
}

export default courses
