import lodash from 'lodash'

const intialState = {
  items: {
    byId: {}
  }
}

export default function paragraphs(state = intialState, action) {
  switch (action.type) {
    case 'ADMIN_SORT_PARAGRAPHS':
      const newItems = lodash.mapKeys(action.payload.data, 'Id')

      const changedObject = Object.keys(state.items.byId).reduce(
        (accumulator, key) => {
          accumulator[key] = { ...state.items.byId[key], ...newItems[key] }
          return accumulator
        },
        {}
      )

      return {
        items: {
          byId: changedObject
        }
      }
    case 'ADMIN_SORT_CHILD_PARAGRAPHS':
      const newItemsFix = action.payload.data.map(paragraph => {
        const newPara = {
          ...paragraph,
          ParentParagraph: { Id: paragraph.ParentParagraph_Id }
        }
        delete newPara['ParentParagraph_Id']
        return newPara
      })
      const newItems1 = lodash.mapKeys(newItemsFix, 'Id')

      const changedObject1 = Object.keys(state.items.byId).reduce(
        (accumulator, key) => {
          accumulator[key] = { ...state.items.byId[key], ...newItems1[key] }
          return accumulator
        },
        {}
      )

      return {
        items: {
          byId: changedObject1
        }
      }
    case 'ADMIN_GET_PARAGRAPHS':
      const items = action.payload.data.value

      return {
        items: {
          byId: {
            ...state.items.byId,
            ...lodash.mapKeys(items, 'Id')
          }
        }
      }
    case 'ADMIN_CREATE_PARAGRAPH':
      const createdParagraph = {
        ...action.payload.data,
        RecommendedParagraphs: [],
        Files: []
      }
      delete createdParagraph['odata.metadata']

      return {
        items: {
          byId: {
            ...state.items.byId,
            [createdParagraph.Id]: createdParagraph
          }
        }
      }
    case 'ADMIN_UPDATE_PARAGRAPH':
      const updatedParagraph = action.payload.data
      delete updatedParagraph['odata.metadata']

      return {
        items: {
          byId: {
            ...state.items.byId,
            [updatedParagraph.Id]: {
              ...state.items.byId[updatedParagraph.Id],
              Name: updatedParagraph.Name
            }
          }
        }
      }
    case 'ADMIN_UPDATE_PARAGRAPH_VISIBILITY':
      let InvisibleForCourses = action.payload.response.data
      let paragraphId5 = action.payload.paragraphId
      let newState = {
        items: {
          byId: {
            ...state.items.byId,
            [paragraphId5]: {
              ...state.items.byId[paragraphId5],
              InvisibleForCourses
            }
          }
        }
      }
      return newState
    case 'ADMIN_DELETE_PARAGRAPH':
      return {
        items: {
          byId: lodash.omit(state.items.byId, action.Id)
        }
      }
    case 'ADMIN_CREATE_FILE':
      let paragraphId = action.payload.paragraphId
      let File = action.payload.file

      return {
        items: {
          byId: {
            ...state.items.byId,
            [paragraphId]: {
              ...state.items.byId[paragraphId],
              Files: [...state.items.byId[paragraphId].Files, File]
            }
          }
        }
      }
    case 'ADMIN_DELETE_FILE':
      const paragraphId2 = action.payload.paragraphId
      const fileId2 = action.payload.fileId

      return {
        items: {
          byId: {
            ...state.items.byId,
            [paragraphId2]: {
              ...state.items.byId[paragraphId2],
              Files: state.items.byId[paragraphId2].Files.filter(
                file => file.Id !== fileId2
              )
            }
          }
        }
      }
    case 'ADMIN_CREATE_RECOMMENDED':
      const paragraphId3 = action.payload.paragraphId
      const recommendedParagraph = action.payload.recommendedParagraph

      return {
        items: {
          byId: {
            ...state.items.byId,
            [paragraphId3]: {
              ...state.items.byId[paragraphId3],
              RecommendedParagraphs: [
                ...state.items.byId[paragraphId3].RecommendedParagraphs,
                recommendedParagraph
              ]
            }
          }
        }
      }
    case 'ADMIN_DELETE_RECOMMENDED':
      const paragraphId4 = action.payload.paragraphId
      const recommendedId = action.payload.recommendedId

      return {
        items: {
          byId: {
            ...state.items.byId,
            [paragraphId4]: {
              ...state.items.byId[paragraphId4],
              RecommendedParagraphs: state.items.byId[
                paragraphId4
              ].RecommendedParagraphs.filter(
                recommended => recommended.Id !== recommendedId
              )
            }
          }
        }
      }
    default:
      return state
  }
}
