import { request } from 'helpers'

const getComments = paragraphId => {
  const url = `odata/Comments?$filter=Paragraph/Id eq ${paragraphId} and Parent eq null&$expand=Children/Parent,Children,Paragraph,Paragraph/Course&$select=Id,Name,CreatedDate,Text,Children/Parent,Children,Paragraph/Id,Paragraph/FriendlyName,Paragraph/Name,Paragraph/Course/FriendlyName,Paragraph/Course/Name`

  return dispatch => {
    return request
      .get(url, 'odata/Comments')
      .then(response => {
        dispatch({
          type: 'GET_COMMENTS',
          payload: { response, paragraphId }
        })
      })
      .catch(error => {
        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export default getComments
