import { request } from 'helpers'

const createScopedComment = (paragraphId, data) => {
  const url = `api/Paragraphs/${paragraphId}/Comments`

  return dispatch => {
    return request.post(url, data).then(response => {
      dispatch({
        type: 'CREATE_SCOPED_COMMENT',
        payload: { data, paragraphId }
      })
    })
  }
}

export default createScopedComment
