import lodash from 'lodash'

const intialState = {
  items: {
    byId: {},
    byCustomerId: {}
  }
}

export default function paragraphs(state = intialState, action) {
  switch (action.type) {
    case 'ADMIN_GET_FRONTPAGE_VIDEOS_PUBLISHER':
      const items = action.payload.data

      return {
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            ...lodash.mapKeys(items, 'Id')
          }
        }
      }
    case 'ADMIN_CREATE_FRONTPAGE_VIDEO_PUBLISHER':
      const createdVideo = {
        ...action.payload.data
      }

      return {
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [createdVideo.Id]: createdVideo
          }
        }
      }
    case 'ADMIN_UPDATE_FRONTPAGE_VIDEO_PUBLISHER':
      const videoId3 = action.payload.videoId
      const Name = action.payload.Name
      let homeVideo = lodash.pickBy(
        state.items.byId,
        video => video.VideoId === videoId3
      )
      homeVideo = homeVideo[Object.keys(homeVideo)[0]]

      return {
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [homeVideo.Id]: {
              ...state.items.byId[homeVideo.Id],
              Name
            }
          }
        }
      }
    case 'ADMIN_SORT_FRONTPAGE_VIDEOS_PUBLISHER':
      const newItems = lodash.mapKeys(action.payload.data, 'Id')

      const changedObject = Object.keys(state.items.byId).reduce(
        (accumulator, key) => {
          accumulator[key] = { ...state.items.byId[key], ...newItems[key] }
          return accumulator
        },
        {}
      )

      return {
        items: {
          ...state.items,
          byId: changedObject
        }
      }
    case 'ADMIN_TOGGLE_FRONTPAGE_VIDEOS_INVISIBILITY':
      const theHomeVideoId = action.payload.homeVideoId
      const theResponse = action.payload.response.data

      return {
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [theHomeVideoId]: {
              ...state.items.byId[theHomeVideoId],
              InVisibleForCustomers: theResponse.isInvisibleFor
            }
          }
        }
      }
    case 'ADMIN_DELETE_FRONTPAGE_VIDEO_PUBLISHER':
      return {
        items: {
          ...state.items,
          byId: lodash.omit(state.items.byId, action.Id)
        }
      }
    case 'ADMIN_GET_FRONTPAGE_VIDEOS_CUSTOMER':
      const customerId = action.payload.customerId
      const items1 = action.payload.response.data

      return {
        items: {
          ...state.items,
          byCustomerId: {
            ...state.items.byCustomerId,
            [customerId]: items1
          }
        }
      }
    case 'ADMIN_CREATE_FRONTPAGE_VIDEO_CUSTOMER':
      const customerId1 = action.payload.customerId
      const createdVideo1 = {
        ...action.payload.response.data
      }

      return {
        items: {
          ...state.items,
          byCustomerId: {
            ...state.items.byCustomerId,
            [customerId1]: [
              ...state.items.byCustomerId[customerId1],
              createdVideo1
            ]
          }
        }
      }
    case 'ADMIN_UPDATE_FRONTPAGE_VIDEO_CUSTOMER':
      const customerId6 = action.payload.obj.customerId
      const videoId5 = action.payload.obj.VideoId
      const Name5 = action.payload.Name

      return {
        items: {
          ...state.items,
          byCustomerId: {
            ...state.items.byCustomerId,
            [customerId6]: state.items.byCustomerId[customerId6].map(
              homeVideo => {
                if (homeVideo.VideoId === videoId5) {
                  return { ...homeVideo, Name: Name5 }
                } else {
                  return homeVideo
                }
              }
            )
          }
        }
      }
    case 'ADMIN_SORT_FRONTPAGE_VIDEOS_CUSTOMER':
      const customerId5 = action.payload.customerId
      const newItems1 = action.payload.response.data

      const newState = {
        items: {
          ...state.items,
          byCustomerId: {
            ...state.items.byCustomerId,
            [customerId5]: state.items.byCustomerId[customerId5].map(video => {
              return {
                ...video,
                ...newItems1.filter(vid => vid.Id === video.Id)[0]
              }
            })
          }
        }
      }

      return newState
    case 'ADMIN_DELETE_FRONTPAGE_VIDEO_CUSTOMER':
      const customerId2 = action.payload.customerId
      const videoId2 = action.payload.videoId

      return {
        items: {
          ...state.items,
          byCustomerId: {
            ...state.items.byCustomerId,
            [customerId2]: state.items.byCustomerId[customerId2].filter(
              video => video.Id !== videoId2
            )
          }
        }
      }
    default:
      return state
  }
}
