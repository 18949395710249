import lodash from 'lodash'

const initialState = {
  selectedId: 0,
  items: {
    byId: {}
  }
}

export default function courses(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_GET_COURSES':
      const items = action.payload.data.value

      return {
        selectedId: state.selectedId,
        items: {
          byId: lodash.mapKeys(items, 'Id')
        }
      }
    case 'ADMIN_GET_COURSE':
      const item = action.payload.data

      return {
        selectedId: item.Id,
        items: {
          byId: {
            ...state.items.byId,
            [item.Id]: item
          }
        }
      }
    case 'ADMIN_UPDATE_COURSE':
      const newCourse = action.payload
      return {
        selectedId: state.selectedId,
        items: {
          byId: {
            ...state.items.byId,
            [newCourse.Id]: lodash.merge(
              state.items.byId[newCourse.Id],
              newCourse
            )
          }
        }
      }
    case 'ADMIN_UPDATE_COURSE_INTRO':
      const newItem = action.meta

      return {
        selectedId: newItem.Id,
        items: {
          byId: {
            ...state.items.byId,
            [newItem.Id]: newItem
          }
        }
      }
    case 'ADMIN_UPDATE_ICON':
      const Icon = action.payload.response.data
      const courseId = action.payload.courseId

      return {
        selectedId: state.selectedId,
        items: {
          byId: {
            ...state.items.byId,
            [courseId]: {
              ...state.items.byId[courseId],
              Icon
            }
          }
        }
      }
    default:
      return state
  }
}
