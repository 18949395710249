export default function customers(state = [], action) {
  switch (action.type) {
    case 'ADMIN_GET_CUSTOMERS':
      return [...action.payload.data.value]
    case 'ADMIN_CREATE_CUSTOMER':
      const createdCustomer = action.payload.data
      return [...state, createdCustomer]
    case 'ADMIN_GET_CUSTOMER':
      const updatedCustomer = action.payload.data

      if (!state.find(customer => customer.Id === updatedCustomer.Id)) {
        return [...state, updatedCustomer]
      }
      return state.map(Customer => {
        if (Customer.Id === updatedCustomer.Id) {
          return updatedCustomer
        }
        return Customer
      })
    case 'ADMIN_UPDATE_CUSTOMER':
      const updatedItem = action.payload.data
      return state.map(item => {
        if (item.Id === updatedItem.Id) {
          return updatedItem
        }
        return item
      })
    case 'ADMIN_UPDATE_LOGO':
      const Logo = action.payload.response.data
      return state.map(customer => {
        if (customer.Id === action.payload.customerId) {
          return { ...customer, Logo }
        }
        return customer
      })
    default:
      return state
  }
}
