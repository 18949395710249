import lodash, { mapKeys } from 'lodash'

const defaultState = {
  selectedId: 0,
  items: {
    byCourseId: {},
    byId: {},
    byPopularity: {}
  }
}

const paragraphs = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_PARAGRAPH':
      let item = action.payload

      return {
        selectedId: item.Id,
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [item.Id]: {
              ...state.items.byId[item.Id],
              ...item
            }
          }
        }
      }
    case 'GET_PARAGRAPHS':
      let newState

      let parentCourseId = action.payload.parentCourse.data.value[0].Course.Id
      let parentCourseParagraphs = action.payload.parentCourse.data.value
        .filter(paragraph => paragraph.ParentParagraph === undefined)
        .sort((a, b) => a.Sort - b.Sort)

      if (!action.payload.childCourse) {
        let byChapterId = {}

        lodash.forIn(parentCourseParagraphs, paragraph => {
          byChapterId = {
            ...byChapterId,
            [paragraph.Chapter.Id]: [
              ...(byChapterId[paragraph.Chapter.Id]
                ? byChapterId[paragraph.Chapter.Id]
                : []),
              paragraph
            ]
          }
        })

        newState = {
          selectedId: state.selectedId,
          items: {
            ...state.items,
            byCourseId: {
              ...state.items.byCourseId,
              [parentCourseId]: {
                byChapterId: byChapterId
              }
            }
          }
        }
      } else if (action.payload.childCourse) {
        let childCourseId = action.payload.childCourseId
        let childCourseParagraphs = action.payload.childCourse.data.value
        let childCourseParagraphsWithParent = action.payload.parentCourse.data.value.filter(
          paragraph => paragraph.ParentParagraph !== undefined
        )

        let byChapterId = {}
        let byParentParagraphId = {}

        lodash.forIn(childCourseParagraphsWithParent, paragraph => {
          byParentParagraphId = {
            ...byParentParagraphId,
            [paragraph.ParentParagraph.Id]: [
              ...(byParentParagraphId[paragraph.ParentParagraph.Id]
                ? byParentParagraphId[paragraph.ParentParagraph.Id]
                : []),
              paragraph
            ]
          }
        })

        lodash.forIn(parentCourseParagraphs, paragraph => {
          const isVisible =
            paragraph.InvisibleForCourses.filter(
              c => c.Id === childCourseId
            )[0] !== undefined

          byChapterId = {
            ...byChapterId,
            [paragraph.Chapter.Id]: [
              ...(byChapterId[paragraph.Chapter.Id]
                ? byChapterId[paragraph.Chapter.Id]
                : []),
              //ChildParagraph BeforeParent
              ...(byParentParagraphId[paragraph.Id]
                ? byParentParagraphId[paragraph.Id]
                    .filter(child => !child.AfterParentParagraph)
                    .sort(
                      (paragraph1, paragraph2) =>
                        paragraph1.Sort - paragraph2.Sort
                    )
                : []),
              //ParentParagraph
              ...(isVisible ? [] : [paragraph]),
              //ChildParagraph AfterParent
              ...(byParentParagraphId[paragraph.Id]
                ? byParentParagraphId[paragraph.Id]
                    .filter(child => child.AfterParentParagraph)
                    .sort(
                      (paragraph1, paragraph2) =>
                        paragraph1.Sort - paragraph2.Sort
                    )
                : [])
            ]
          }
        })

        lodash.forIn(childCourseParagraphs, paragraph => {
          byChapterId = {
            ...byChapterId,
            [paragraph.Chapter.Id]: [
              ...(byChapterId[paragraph.Chapter.Id]
                ? byChapterId[paragraph.Chapter.Id]
                : []),
              paragraph
            ]
          }
        })

        newState = {
          selectedId: state.selectedId,
          items: {
            ...state.items,
            byCourseId: {
              ...state.items.byCourseId,
              [childCourseId]: {
                byChapterId: byChapterId
              }
            }
          }
        }
      }

      return newState
    case 'GET_POPULAR_PARAGRAPHS':
      let items = action.payload.data

      return {
        selectedId: state.selectedId,
        items: {
          ...state.items,
          byPopularity: mapKeys(items, 'Id')
        }
      }
    case 'UPDATE_LIKES':
      let item2 = action.payload.data

      return {
        selectedId: state.selectedId,
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [item2.Id]: {
              ...state.items.byId[item2.Id],
              Likes: item2.Likes,
              Liked: true
            }
          }
        }
      }
    case 'CREATE_COMMENT': {
      let newComment = action.payload.response.data
      delete newComment['odata.metadata']
      let paragraphId = action.payload.paragraphId
      let comments

      if (newComment.Parent) {
        comments = state.items.byId[paragraphId].Comments.map(comment => {
          if (comment.Id === newComment.Parent.Id) {
            return {
              ...comment,
              Children: [
                ...(comment.Children ? comment.Children : []),
                newComment
              ]
            }
          } else {
            return comment
          }
        })
      } else {
        comments = [...state.items.byId[paragraphId].Comments, newComment]
      }

      return {
        ...state,
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [paragraphId]: {
              ...state.items.byId[paragraphId],
              Comments: comments
            }
          }
        }
      }
    }
    case 'CREATE_SCOPED_COMMENT': {
      const { data, paragraphId } = action.payload
      const ParentCommentId = data.ParentCommentId

      let comments

      if (ParentCommentId) {
        comments = state.items.byId[paragraphId].Comments.map(comment => {
          if (comment.Id === ParentCommentId) {
            return {
              ...comment,
              Children: [...(comment.Children ? comment.Children : []), data]
            }
          } else {
            return comment
          }
        })
      } else {
        comments = [...state.items.byId[paragraphId].Comments, data]
      }

      return {
        ...state,
        items: {
          ...state.items,
          byId: {
            ...state.items.byId,
            [paragraphId]: {
              ...state.items.byId[paragraphId],
              Comments: comments
            }
          }
        }
      }
    }
    default:
      return state
  }
}

export default paragraphs
