import lodash from 'lodash'

const initialState = {
  selectedId: 0,
  items: {
    byId: {}
  }
}

export default function categories(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_GET_CATEGORIES':
      const items = action.payload.data.value

      return {
        selectedId: state.selectedId,
        items: {
          byId: lodash.mapKeys(items, 'Id')
        }
      }
    case 'ADMIN_DELETE_CATEGORY':
      return {
        selectedId: state.selectedId,
        items: {
          byId: lodash.omit(state.items.byId, action.Id)
        }
      }
    default:
      return state
  }
}
