import { request } from 'helpers'

const getScopedChapter = (ChapterId, CourseId) => dispatch =>
  request
    .get(`api/ParsedChapters/${ChapterId}`, 'ParsedChapters')
    .then(response =>
      dispatch({
        type: 'GET_SCOPED_CHAPTER',
        payload: { Chapter: response.data, CourseId }
      })
    )

export default getScopedChapter
