import { request } from 'helpers'

const getCourses = () => dispatch =>
  request
    .get(
      `odata/Courses?$expand=Category,Parent,Icon&$orderby=Name&$filter=Type eq 'Standard'`
    )
    .then(response =>
      dispatch({
        type: 'GET_COURSES',
        payload: response
      })
    )

export default getCourses
