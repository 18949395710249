import React from 'react'
import TooltipComponent from '@material-ui/core/Tooltip'
import IconButtonComponent from '@material-ui/core/IconButton'

export default function IconButton({
  title,
  onClick,
  icon: Icon,
  style,
  size,
  color,
  disabled
}) {
  return (
    <TooltipComponent title={title || undefined} placement="bottom">
      <div>
        <IconButtonComponent
          color={color}
          style={{ padding: 6, ...style }}
          disabled={disabled}
          onClick={onClick}
        >
          <Icon style={{ fontSize: size }} />
        </IconButtonComponent>
      </div>
    </TooltipComponent>
  )
}
