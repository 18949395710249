import { request } from 'helpers'

const getScopedParagraph = FriendlyName => {
  const url = `api/Paragraphs/${FriendlyName}`

  return dispatch => {
    return request.get(url).then(response =>
      dispatch({
        type: 'GET_PARAGRAPH',
        payload: response.data
      })
    )
  }
}

export default getScopedParagraph
