const danish = {
  // Seach
  search: 'Søg',
  searchForParagraphsOrCourses: 'Søg efter afsnit eller kurser',
  paragraph: 'Afsnit',
  course: 'Kursus',

  // Activity
  activity: 'Aktivitet',
  popularParagraphs: 'Populære afsnit',
  newComments: 'Nye kommentarer',

  // Courses
  courses: 'Kurser',
  welcomeTo: 'Velkommen til',
  showParagraphs: 'Vis afsnit',
  hideParagraphs: 'Skjul afsnit',
  previous: 'Forrige',
  next: 'Næste',
  comments: 'kommentarer',
  share: 'Del',
  files: 'filer',
  relatedParagraphs: 'relaterede afsnit',
  subscribeMailtips: 'Tilmeld mailtips',

  // General
  signOut: 'Log ud',
  like: 'synes godt om'
}

export default danish
