export default function users(state = [], action) {
  switch (action.type) {
    case 'ADMIN_UPDATE_LICENSE':
      return state.map(item => {
        if (item.Id === action.payload.licenseId) {
          return {
            ...item,
            StartDate: action.payload.startDate,
            EndDate: action.payload.endDate
          }
        }
        return item
      })

    case 'ADMIN_GET_LICENSES':
      return [...action.payload.data.value]
    case 'ADMIN_CREATE_LICENSE':
      const createdItem = {
        ...action.payload.data,
        Courses: [],
        Customer: { Id: action.meta }
      }

      return [...state, createdItem]
    case 'ADMIN_CREATE_LICENSE_COURSE':
      const newCourse = action.course
      const newState = state.map(License => {
        if (License.Id === action.licenseId) {
          return { ...License, Courses: [...License.Courses, newCourse] }
        }
        return License
      })

      return newState
    case 'ADMIN_DELETE_LICENSE':
      return state.filter(item => item.Id !== action.Id)
    case 'ADMIN_DELETE_LICENSE_COURSE':
      return state.map(License => {
        if (License.Id === action.licenseId) {
          return {
            ...License,
            Courses: License.Courses.filter(item => item.Id !== action.courseId)
          }
        }
        return License
      })
    default:
      return state
  }
}
