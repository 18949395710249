import { request } from 'helpers'

const getCourse = courseId => dispatch =>
  request.get(`api/ParsedCourses/${courseId}`, 'ParsedCourses').then(response =>
    dispatch({
      type: 'GET_COURSE',
      payload: response.data
    })
  )

export default getCourse
