const defaultState = {
  is_scoped: false,
  token: undefined,
  scope: undefined,
  scopeId: undefined,
  BestRole: undefined
}

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'GET_USER_INFO': {
      let userInfo = action.payload.data
      let Roles = action.payload.data.Roles
      let BestRole

      if (Roles.filter(role => role.Name === 'SystemAdministrator')[0].Active) {
        BestRole = 'SystemAdministrator'
      } else if (Roles.filter(role => role.Name === 'Publisher')[0].Active) {
        BestRole = 'Publisher'
      } else if (Roles.filter(role => role.Name === 'Organisation')[0].Active) {
        BestRole = 'Organisation'
      } else if (Roles.filter(role => role.Name === 'Plus')[0].Active) {
        BestRole = 'Plusbruger'
      }

      return {
        ...state,
        ...userInfo,
        BestRole
      }
    }
    case 'SIGN_IN':
      const userInfo = action.payload.data

      return {
        ...state,
        ...userInfo,
        token: userInfo.access_token
      }
    case 'REFRESH_TOKEN':
      return {
        ...state,
        token: action.payload.data.access_token
      }
    case 'SET_TOKEN':
      const user = action.payload
      const token = user.Token
      let scope = undefined
      let scopeId = undefined

      if (user.AccessScopes[0]) {
        scope = user.AccessScopes[0].Key
        scopeId = user.AccessScopes[0].Value
      }

      return {
        ...state,
        token,
        scope,
        scopeId,
        is_scoped: action.payload.scoped
      }
    case 'SIGN_OUT':
      sessionStorage.clear()
      return {}
    default:
      return state
  }
}
