import { request } from 'helpers'

const getScopedCourse = courseFriendlyName => dispatch =>
  request
    .get(`api/Courses/${courseFriendlyName}/info`, 'ScopedCourse')
    .then(response =>
      dispatch({
        type: 'GET_COURSE',
        payload: response.data
      })
    )

export default getScopedCourse
