import React, { useState, useEffect } from 'react'
import { useTransition } from 'react-spring'
import IconButton from 'components/IconButton'
import Typography from 'components/Typography'
import Space from 'components/Space'
import { css } from 'emotion'
import { animated } from 'react-spring'
import Close from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'

let id = 0

export default function Notifications({
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 3000,
  children
}) {
  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([])
  const transitions = useTransition(items, item => item.key, {
    from: { opacity: 0, height: 0, marginTop: 0, life: '100%' },
    enter: item => async next =>
      await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight,
        marginTop: 10
      }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({ life: '0%' })
      await next({ opacity: 0 })
      await next({ height: 0, marginTop: 0 })
    },
    onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
    config: (item, state) =>
      state === 'leave' ? [{ duration: timeout }, config, config] : config
  })

  useEffect(
    () =>
      void children((msg, type) =>
        setItems(state => [...state, { key: id++, msg, type }])
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Container>
      {transitions.map(({ key, item, props: { life, ...style } }) => {
        const Icon = item.type === 'error' ? ErrorIcon : CheckIcon
        const color = item.type === 'error' ? 'red' : 'green'
        return (
          <animated.div
            key={key}
            style={style}
            className={css({
              boxSizing: 'border-box',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 3,
              width: '40ch',
              '@media (max-width: 680px)': {
                width: '100%'
              }
            })}
          >
            <div
              ref={ref => ref && refMap.set(item, ref)}
              className={css({
                background: '#445159',
                borderLeft: `5px solid ${color}`,
                opacity: 0.9,
                boxSizing: 'border-box',
                padding: '10px 10px',
                fontSize: '1em',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                borderRadius: 3
              })}
            >
              <div
                className={css({
                  flex: 1,
                  display: 'flex',
                  alignItems: 'flex-start',
                  maxHeight: 58,
                  overflow: 'hidden'
                })}
              >
                <Icon style={{ color }} />
                <Space size={10} />
                <Typography style={{ flex: 1, color: 'white' }}>
                  {item.msg}
                </Typography>
                <Space size={10} />
                <IconButton
                  size={16}
                  style={{ color: 'white', pointerEvents: 'auto' }}
                  icon={Close}
                  onClick={e => {
                    e.stopPropagation()
                    cancelMap.has(item) && cancelMap.get(item)()
                  }}
                />
              </div>
              {/* <animated.div
                style={{ right: life }}
                className={css({
                  position: 'absolute',
                  bottom: 0, // props.top ? 10 : 0
                  left: 0,
                  width: 'auto',
                  backgroundImage: 'linear-gradient(130deg, #00b4e6, #00f0e0)',
                  height: 5,
                  zIndex: -10
                })}
              /> */}
            </div>
          </animated.div>
        )
      })}
    </Container>
  )
}

function Container(props) {
  return (
    <div
      className={css({
        position: 'fixed',
        zIndex: 1000,
        width: '0 auto',
        top: props.top ? '30px' : 'unset',
        bottom: props.top ? 'unset' : '30px',
        margin: '0 auto',
        left: 30,
        right: 30,
        display: 'flex',
        flexDirection: props.top ? 'column-reverse' : 'column',
        userSelect: 'none',
        pointerEvents: 'none',
        alignItems:
          props.position === 'center'
            ? 'center'
            : `flex-${props.position || 'end'}`,
        '@media (max-width: 680px)': {
          alignItems: 'center'
        }
      })}
      {...props}
    />
  )
}
