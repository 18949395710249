import { request } from 'helpers'

const getParagraph = (courseId, FriendlyName, omitStats = false) => {
  const url = `api/Courses/${courseId}/Paragraphs/${FriendlyName}${
    omitStats ? '?omitStats=true' : ''
  }`

  return dispatch => {
    return request.get(url, 'Paragraphs').then(response =>
      dispatch({
        type: 'GET_PARAGRAPH',
        payload: response.data
      })
    )
  }
}

export default getParagraph
