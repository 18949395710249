import { keyframes as emotionKeyframes } from 'emotion'

export default class keyframes {
  static fadeIn = emotionKeyframes({
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  })
}
