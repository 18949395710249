export default function users(state = [], action) {
  switch (action.type) {
    case 'ADMIN_UPDATE_USER':
      let Roles = action.payload.response.data
        .filter(x => x.Active)
        .map(r => {
          return { UserId: userId, RoleId: r.Id }
        })
      let userId = action.payload.userId

      return state.map(user => {
        if (user.Id === userId) {
          return { ...user, Roles }
        }
        return user
      })
    case 'ADMIN_GET_USERS':
      return [...action.payload.data.value]
    case 'ADMIN_CREATE_USER':
      const createdUser = action.payload.data
      return [...state, { ...createdUser, Customer: { Id: action.meta } }]
    case 'ADMIN_DELETE_USER':
      return state.filter(User => User.Id !== action.Id)
    default:
      return state
  }
}
