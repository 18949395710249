import { request } from 'helpers'

const createComment = (ParagraphId, Name, Email, Text, ParentId = null) => {
  // const url = `odata/Comments?$expand=Parent,Children,Paragraph/Course`
  const url = `api/Paragraphs/${ParagraphId}/Comments`

  const data = {
    Name,
    Email,
    Text,
    ParentId,
    ParagraphId
  }

  return dispatch => {
    return request
      .post(url, data)
      .then(response => {
        dispatch({
          type: 'CREATE_COMMENT',
          payload: {
            response: { data },
            paragraphId: ParagraphId
          }
        })
      })
      .catch(error => {
        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export default createComment
