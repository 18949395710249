import { request } from 'helpers'

const updateLikes = paragraphId => {
  const url = `api/Paragraphs(${paragraphId})/Like`

  return dispatch => {
    return request
      .post(url)
      .then(response => {
        dispatch({
          type: 'UPDATE_LIKES',
          payload: response
        })
      })
      .catch(error => {
        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export default updateLikes
