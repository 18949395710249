const initialState = {
  items: {
    byCourseId: {}
  }
}

export default function chapters(state = initialState, action) {
  switch (action.type) {
    case 'GET_CHAPTERS':
      let chapters = []
      let childChapters = []
      let courseId = action.payload.parentCourse.data.value[0].Course.Id
      let parentChapters = action.payload.parentCourse.data.value.sort(
        (chapter1, chapter2) => chapter1.Sort - chapter2.Sort
      )

      if (action.payload.childCourse) {
        courseId = action.payload.childCourseId
        childChapters = action.payload.childCourse.data.value

        parentChapters.forEach(chapter => {
          let beforeChildChapters = childChapters
            .filter(
              child =>
                child.ParentChapter.Id === chapter.Id &&
                !child.AfterParentChapter
            )
            .sort((chapter1, chapter2) => chapter1.Sort - chapter2.Sort)
          let isVisible =
            chapter.InvisibleForCourses.filter(c => c.Id === courseId)[0] ===
            undefined

          let afterChildChapters = childChapters
            .filter(
              child =>
                child.ParentChapter.Id === chapter.Id &&
                child.AfterParentChapter
            )
            .sort((chapter1, chapter2) => chapter1.Sort - chapter2.Sort)

          chapters = [
            ...chapters,
            ...beforeChildChapters,
            ...(isVisible ? [chapter] : []),
            ...afterChildChapters
          ]
        })
      } else {
        chapters = parentChapters
      }

      return {
        items: {
          byCourseId: {
            ...state.items.byCourseId,
            [courseId]: chapters
          }
        }
      }
    default:
      return state
  }
}
