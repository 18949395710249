const intialState = {
  showMenu: false
}

export default function settings(state = intialState, action) {
  switch (action.type) {
    case 'SHOW_MENU':
      return { showMenu: true }
    case 'HIDE_MENU':
      return { showMenu: false }
    default:
      return state
  }
}
