import { combineReducers } from 'redux'

import user from './portal/user'
import settings from './portal/settings'
import categories from './portal/categories'
import courses from './portal/courses'
import chapters from './portal/chapters'
import paragraphs from './portal/paragraphs'
import comments from './portal/comments'

import adminErrors from './admin/errors'
import adminFrontpageVideos from './admin/frontpageVideos'
import adminCustomers from './admin/customers'
import adminLicenses from './admin/licenses'
import adminUsers from './admin/users'
import adminRoles from './admin/roles'
import adminCategories from './admin/categories'
import adminCourses from './admin/courses'
import adminChapters from './admin/chapters'
import adminParagraphs from './admin/paragraphs'

const reducers = combineReducers({
  user,
  settings,
  categories,
  courses,
  chapters,
  paragraphs,
  comments,

  //admin
  adminErrors,
  adminFrontpageVideos,
  adminCustomers,
  adminLicenses,
  adminUsers,
  adminRoles,
  adminCategories,
  adminCourses,
  adminChapters,
  adminParagraphs
})

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    state = undefined
  }

  return reducers(state, action)
}

export default rootReducer
