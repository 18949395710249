import { createMuiTheme } from '@material-ui/core/styles'
import { styles } from 'helpers'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 13
  },
  palette: {
    primary: {
      light: styles.colors.primary,
      main: styles.colors.primary,
      dark: styles.colors.primary
    },
    secondary: {
      light: styles.colors.secondary,
      main: styles.colors.secondary,
      dark: styles.colors.secondary
    }
  }
})

export default theme
