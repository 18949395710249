export default class format {
  static monthNames = [
    'Januar',
    'Februar',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'December'
  ]

  static weekdayNames = [
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag',
    'Søndag'
  ]

  static date = date => {
    const newDate = new Date(date)

    const weekday = format.weekdayNames[newDate.getDay()]
    const day = newDate.getDate()
    const monthNumber = newDate.getMonth()
    const month = format.monthNames[monthNumber]
    const year = newDate.getFullYear()

    let hours = newDate.getHours() + ''

    if (hours.length === 1) {
      hours = '0' + hours
    }

    let minutes = newDate.getMinutes() + ''

    if (minutes.length === 1) {
      minutes = '0' + minutes
    }

    return {
      shortDate: `${day}/${monthNumber}–${year}`,
      weekday: weekday.substring(0, 3).toLowerCase(),
      date: `${day} ${month.substring(0, 3).toLowerCase()}`,
      year,
      fullDate: `${weekday}, d. ${day}. ${month.toLowerCase()} ${year}`,
      time: `${hours}:${minutes}`
    }
  }
}
