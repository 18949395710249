import store from 'store'

const minRole = requiredRole => {
  const priority = role => {
    switch (role) {
      case 'Unachievable':
        return 12
      case 'SystemAdministrator':
        return 10
      case 'Publisher':
        return 8
      case 'Organisation':
        return 6
      case 'Plusbruger':
        return 4
      default:
        return 2
    }
  }

  if (priority(store.getState().user.BestRole) >= priority(requiredRole)) {
    return true
  } else {
    return false
  }
}

export default minRole
