const defaultState = {
  items: {
    byId: {},
  },
}

export default function users(state = defaultState, action) {
  switch (action.type) {
    case 'ADMIN_CLOSE_ERROR':
      return {
        items: {
          byId: {
            ...state.items.byId,
            [action.payload]: {
              ...state.items.byId[action.payload],
              show: false,
            },
          },
        },
      }
    case 'ADMIN_ERROR':
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.Message
      ) {
        const nextId = Object.keys(state.items.byId).length + 1
        const message = action.payload.response.data.Message

        return {
          items: {
            byId: {
              ...state.items.byId,
              [nextId]: {
                Id: nextId,
                message,
                show: true,
              },
            },
          },
        }
      }
      return state
    default:
      return state
  }
}
