import { mapKeys } from 'lodash'

const defaultState = {
  items: {
    byName: {},
    byId: {}
  }
}

export default function users(state = defaultState, action) {
  switch (action.type) {
    case 'ADMIN_GET_ROLES':
      let items = action.payload.data
      return {
        items: {
          byName: mapKeys(items, 'Name'),
          byId: mapKeys(items, 'Id')
        }
      }
    default:
      return state
  }
}
