import React from 'react'
import { css } from 'emotion'

const View = ({ className, style, children, ...props }) => (
  <div className={css(style)} {...props}>
    {children}
  </div>
)

export default View
