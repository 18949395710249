import { request } from 'helpers'

const getPopularParagraphs = () => {
  const url = 'api/MostLikedParagraphs'

  return dispatch => {
    return request
      .get(url, 'PopularParagraphs')
      .then(response => {
        dispatch({
          type: 'GET_POPULAR_PARAGRAPHS',
          payload: response
        })
      })
      .catch(error => {
        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export default getPopularParagraphs
