const defaultState = {
  selectedCategoryId: 0,
  selectedSubcategoryId: 0,
  items: {
    byId: {}
  }
}

const categories = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_CATEGORY':
      const { categoryId, subcategoryId } = action.payload

      return {
        selectedCategoryId: categoryId,
        selectedSubcategoryId: subcategoryId,
        items: {
          ...state.items
        }
      }
    case 'GET_CATEGORIES':
      let items = action.payload.data.value
      let parentCategories = {}

      items.forEach(category => {
        let parent = undefined
        let child = undefined

        if (category.Parent) {
          parent = category.Parent
          child = category
        } else {
          parent = category
        }

        let Children = child
          ? [
              ...(parentCategories[parent.Id]
                ? parentCategories[parent.Id].Children
                : []),
              child
            ]
          : [
              ...(parentCategories[parent.Id]
                ? parentCategories[parent.Id].Children
                : [])
            ]

        Children.sort((a, b) => a.Sort - b.Sort)

        parentCategories = {
          ...parentCategories,
          [parent.Id]: {
            ...(parentCategories[parent.Id] ? parentCategories[parent.Id] : {}),
            ...(parent ? parent : {}),
            Children
          }
        }
      })

      return {
        ...state,
        items: {
          byId: parentCategories
        }
      }
    default:
      return state
  }
}

export default categories
