import { keyframes } from 'emotion'

const fadeInUp = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate3d(0, -1%, 0)'
  },
  '50%': {
    opacity: 0.5,
    transform: 'translate3d(0, -0.5%, 0)'
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }
})

const fadeIn = { animation: `${fadeInUp} 400ms ease` }

export default fadeIn
