import React from 'react'
import Loading from 'components/Loading'
import View from 'components/View'
import Fade from '@material-ui/core/Fade'

export default function LoadingContainer() {
  return (
    <View
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Fade in style={{ transitionDelay: '800ms' }}>
        <Loading color="primary" size={96} />
      </Fade>
    </View>
  )
}
